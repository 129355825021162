@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

#clients {
  font-family: Montserrat, sans-serif;
  color: #000;
  padding: 30px 0 0;
  margin-left: 0;
}

.clients-heading {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
}

.client-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px 20px;
  padding: 30px 50px 0;
}

.client {
  display: flex;
  align-items: center;
  width: 400px;
  opacity: 0.8;
}

.clientt {
  display: flex;
  align-items: center;
  width: 370px;
  opacity: 0.8;
}

.client-img {
  background: #333;
  overflow: hidden;
  top: 20px;
  width: 200px;
  height: 100px;
  border-radius: 10px;
}

img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.client-name {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  width: 380px;
}

.clients-footer {
  text-align: center;
}

.end-of-clients {
  font-size: 19px;
  font-weight: 300;
  opacity: 0.8;
}

.client-msg {
  background-color: #eef1ff;
  padding: 20px;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
}
