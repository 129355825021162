@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

#header {
  font-family: Montserrat, sans-serif;
  background-image: url("../img/header.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 50px;
  height: 470px;
  width: 100%;
}

.header-txt {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 0, 0, 0.8) 70%,
    rgba(0, 0, 0, 0.4) 85%,
    rgba(0, 0, 0, 0) 100%
  );
  color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  top: 0;
  left: 0;
  height: 170px;
  padding: 5em 3em;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
}

.header-txt-bold {
  font-size: 30px;
  font-weight: 900;
}

.enquire-now {
  font-family: Montserrat, sans-serif;
  background: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 15px 20px;
  width: 150px;
  font-size: 15px;
  font-weight: 600;
}

.enquire-now:hover {
  background: #d4d4d4;
  color: #000;
}
