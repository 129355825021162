@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

#services {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #11131e;
}

.services-heading {
  font-size: 30px;
  font-weight: 900;
  margin-top: 60px;
  color: #fff;
}

.service-cards {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 90px 25px;
  padding: 80px 10px;
}

.service-cards .card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  max-width: 100%;
  height: 265px;
  background: white;
  border-radius: 20px;
  transition: 0.5s;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.service-cards .card:hover {
  height: 260px;
  cursor: pointer;
}

.service-cards .card .img-box {
  position: absolute;
  top: 20px;
  width: 250px;
  height: 170px;
  background: #333;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.5s;
}

.service-cards .card:hover .img-box {
  top: -80px;
  scale: 0.9;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.service-cards .card .img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-cards .card .content {
  position: absolute;
  top: 185px;
  width: 100%;
  height: 70px;
  padding: 0 30px;
  text-align: center;
  overflow: hidden;
  transition: 0.5s;
}

.service-cards .card:hover .content {
  top: 80px;
  height: 200px;
}

.service-cards .card .content h2 {
  font-size: 21px;
  font-weight: 700;
  margin: 30px 20px 0;
}

.service-cards .card .content p {
  color: #333;
  margin: 15px 18px;
}

@media (max-width: 480px) {
  .service-cards .card {
    width: 230px;
    border-radius: 15px;
  }

  .service-cards .card .img-box {
    width: 185px;
    border-radius: 10px;
  }

  .service-cards .card .content p {
    font-size: 0.8rem;
  }
}
