@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

#contact-us {
  font-family: Montserrat, sans-serif;
  background-color: #0a0916;
  color: #fff;
  padding: 30px 0 10px;
  margin-left: 0;
}

.contact-us-heading {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
}

.contact-details {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  gap: 20px;
  opacity: 0.8;
}

.icon {
  font-size: 30px;
}

.title {
  font-size: 22px;
  font-weight: 500;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
}

.sa-loc {
  width: 95%;
  height: 600px;
  padding: 0 30px;
}

#contact-form {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  margin-top: 30px;
}

input {
  font-family: Montserrat, sans-serif;
  color: #fff;
  background: transparent;
  border: 1px solid #f0fcff;
  border-radius: 5px;
  padding: 20px 10px;
  width: 500px;
}

.mobile-input {
  width: 80%;
}

input::placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
}

textarea {
  font-family: Montserrat, sans-serif;
  resize: none;
  color: #fff;
  background: transparent;
  border: 1px solid #f0fcff;
  border-radius: 5px;
  padding: 20px 10px;
  width: 500px;
}

textarea::placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
}

.submit-form {
  font-family: Montserrat, sans-serif;
  background: #f0fcff;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px 30px;
  width: 150px;
  font-size: 15px;
  font-weight: 600;
}

.submit-form:hover {
  background: #d4d4d4;
  color: #000;
}

.copyright {
  text-align: center;
  margin-top: 50px;
  opacity: 0.4;
}
